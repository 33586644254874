import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contentbox = () => {

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_3gpe59e', 'template_v4glb3a', form.current, {
        publicKey: '492WXwA0luLmR0MD6',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
        e.target.reset()
  };


    return(
        <section className="contact">
            <div className="container">
                <div className="row">
                    <div className="contact-caption clearfix">
                        <div className="col-md-5 contact-info text-left">
					    	<h3>informacion de contacto</h3>
					    	<div className="info-detail">
					    		<ul><li><i className="fa fa-calendar"></i><span>Lunes - Viernes:</span> 8:00 AM a 5:00 PM</li></ul>
                  <ul><li><i className="fa fa-house"></i><span>Dirección:</span> Diag 85 # 76-21 Edif Magané</li></ul>
					    		<ul><li><i className="fa fa-phone"></i><span>Teléfono:</span> (601) 9275401</li></ul>
					    		<ul><li><i className="fa fa-phone"></i><span>Celular:</span> (+57) 315 594 0792 - (+57) 320 235 6529</li></ul>
					    		<ul><li><i className="fa fa-envelope"></i><span>Email:</span> comprasnidialmedicalcolombia@gmail.com</li></ul>
					    	</div>
					    </div>
                        <div className="col-md-6 col-md-offset-1 contact-form">
						<h3>cotice con nostros!!</h3>

						<form className="form" ref={form} onSubmit={sendEmail}>
							<input className="name" name="user_name" type="text" placeholder="Nombre/Razón Social" required/>
							<input className="email" name="user_email" type="email" placeholder="Email" required/>
							<input className="phone" name="user_phone" type="text" placeholder="Teléfono" required/>
							<textarea className="message" name="message" id="message" cols="30" rows="10" placeholder="Mensaje" required></textarea>
							<input className="submit-btn" type="submit" value="Enviar"/>
						</form>
					</div>
                    </div>
                </div>

            </div>
        <a href="https://api.whatsapp.com/send?phone=573202356529" className="btn-wsp" target="_blank" rel="noreferrer">
          <i className="fa fa-whatsapp icono"></i>
        </a>
        </section>
    )
    
}

export default Contentbox;